import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {NotificationService} from './Services/Shared/notification.service';
import {AppRoutingModule} from './app-routing.module';
import {ApiModule} from './Services/API/api.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UtilityService} from './Services/Shared/utility.service';
import {WebsiteModule} from './Components/Website/website.module';
import {SharedModule} from './Components/Shared/shared.module';
import {ScreenSizeService} from './Services/Shared/screenSize.service';
import {MetatagsService} from "./Services/Shared/metatags.service";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ApiModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        WebsiteModule,
        SharedModule,
    ],
    providers: [
        NotificationService,
        UtilityService,
        ScreenSizeService,
        MetatagsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
