import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/internal/operators';
import {NotificationService} from '../Shared/notification.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

    constructor(
        private notificationService: NotificationService,
        private router: Router
    ) {}

    errorHandler(error) {
        if (error.status === 404) {
            this.notificationService.send("404", 'error');
        } else {

            console.error(error);
            this.notificationService.send("Errore generico nella richiesta, riprovare più tardi", 'error');
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        //this.loaderService.stop();
                    }
                },
                error => this.errorHandler(error)
            )
        );
    }
}
