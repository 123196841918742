<div
        class="notification notification-{{notification.type}} clearfix"
        *ngFor="let notification of notificationsStack; let i = index"
        [@showNotification]
        (click)="unStack(i)"
        [style.top]="top"
>
    <div class="notification-holder clearfix">
        <div class="notification-description">
            {{notification.message}}
        </div>
    </div>
</div>