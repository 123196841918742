<section id="destinazione" *ngIf="destinazione">
    <div id="destinazione-head">
        <div id="destinazione-head-container">
            <div id="destinazioni-head-image-container">
                <img src="/assets/images/header_dot_shadow.svg" />
            </div>
            <div id="destinazione-nazione">
                {{destinazione.nazione}}
            </div>
            <div id="destinazione-capitale">
                <div>Capitale:</div>
                <div>{{destinazione.capitale}}</div>
            </div>
            <div id="destinazione-head-info" class="clearfix">
                <div>
                    <span>Continente: </span>
                    <span>{{destinazione.continente}}</span>
                </div>
                <div>
                    <span>Popolazione: </span>
                    <span>{{destinazione.popolazione | formatnumber}}</span>
                </div>
                <div>
                    <span>Superficie: </span>
                    <span>{{destinazione.superficie | integer | formatnumber}} Km<sup>2</sup></span>
                </div>
            </div>
        </div>
    </div>
    <div id="destinazione-bar">
        <div id="destinazione-bar-content">
            <div id="destinazione-bar-menu">
                <div
                    [ngClass]="{'destinazione-bar-menu-element': true, 'destinazione-bar-menu-element-active': selectedMenu == 'fuso'}"
                    (click)="selectedMenu = 'fuso'"
                >
                    Fuso orario
                </div>
                <div
                    [ngClass]="{'destinazione-bar-menu-element': true, 'destinazione-bar-menu-element-active': selectedMenu == 'lingua'}"
                    (click)="selectedMenu = 'lingua'"
                >
                    lingua
                </div>
                <div
                    [ngClass]="{'destinazione-bar-menu-element': true, 'destinazione-bar-menu-element-active': selectedMenu == 'religione'}"
                    (click)="selectedMenu = 'religione'"
                >
                    religione
                </div>
                <div
                    [ngClass]="{'destinazione-bar-menu-element': true, 'destinazione-bar-menu-element-active': selectedMenu == 'moneta'}"
                    (click)="selectedMenu = 'moneta'"
                >
                    moneta
                </div>
                <div
                    [ngClass]="{'destinazione-bar-menu-element': true, 'destinazione-bar-menu-element-active': selectedMenu == 'clima'}"
                    (click)="selectedMenu = 'clima'"
                >
                    clima
                </div>
                <div
                    [ngClass]="{'destinazione-bar-menu-element': true, 'destinazione-bar-menu-element-active': selectedMenu == 'ambasciata'}"
                    (click)="selectedMenu = 'ambasciata'"
                >
                    ambasciata
                </div>
            </div>

            <div id="destinazione-bar-data">
                <div *ngIf="selectedMenu == 'fuso'">
                    {{destinazione.fuso_orario}}
                </div>
                <div *ngIf="selectedMenu == 'lingua'">
                    {{destinazione.lingua}}
                </div>
                <div *ngIf="selectedMenu == 'religione'">
                    {{destinazione.religione}}
                </div>
                <div *ngIf="selectedMenu == 'moneta'">
                    {{destinazione.moneta}}
                </div>
                <div *ngIf="selectedMenu == 'clima'">
                    {{destinazione.clima}}
                </div>
                <div *ngIf="selectedMenu == 'ambasciata'">
                    {{destinazione.ambasciata}}
                </div>
            </div>
        </div>
    </div>
    <div id="destinazione-content">
        <div id="destinazioni-content-box">
            <div class="clearfix" id="destinazione-content-head">
                <div (click)="selectedcontent = 'vaccinazioni'" [ngClass]="{'destinazione-content-head-selected' : selectedcontent == 'vaccinazioni'}">
                    Vaccinazioni
                </div>
                <div (click)="selectedcontent = 'sanitaria'" [ngClass]="{'destinazione-content-head-selected' : selectedcontent == 'sanitaria'}">
                    Situazione sanitaria
                </div>
            </div>
            <div id="destinazione-content_body">
                <div *ngIf="selectedcontent == 'vaccinazioni'">
                    <div class="destinazione-section-title">
                        Febbre gialla
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.v_febbre_gialla.length > 0">
                        {{destinazione.v_febbre_gialla}}
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.v_febbre_gialla.length <= 0">
                        Nessun obbligo di vaccinazione per i viaggiatori internazionali
                    </div>

                    <div class="destinazione-section-title">
                        Malaria
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.v_malaria.length > 0">
                        {{destinazione.v_malaria}}
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.v_malaria.length <= 0">
                        Nessun obbligo di vaccinazione per i viaggiatori internazionali
                    </div>

                    <div class="destinazione-section-title">
                        Profilassi
                    </div>
                    <div class="destinazione-section-content">
                        <span
                            *ngIf="destinazione.v_tipo_profilassi != null && destinazione.v_tipo_profilassi.tipi.length > 0"
                        >
                            <div *ngFor="let prof of destinazione.v_tipo_profilassi.tipi; let i = index">
                                <strong>Tipo {{prof.tipo}}</strong> {{prof.info}}
                            </div>
                        </span>
                        <span *ngIf="destinazione.v_tipo_profilassi.length <= 0 || destinazione.v_tipo_profilassi.tipi.length <= 0">
                            Nessuna profilassi raccomandata
                        </span>
                    </div>
                </div>

                <div *ngIf="selectedcontent == 'sanitaria'">
                    <div class="destinazione-section-title">
                        Strutture sanitarie
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.ss_struttura_sanitaria.length > 0">
                        {{destinazione.ss_struttura_sanitaria}}
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.ss_struttura_sanitaria.length <= 0">
                        Nessun dato presente.
                    </div>

                    <div class="destinazione-section-title">
                        Malattie presenti
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.ss_malattie_presenti.length > 0">
                        {{destinazione.ss_malattie_presenti}}
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.ss_malattie_presenti.length <= 0">
                        Nessun dato presente.
                    </div>

                    <div class="destinazione-section-title">
                        Avvertenze
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.ss_avvertenze.length > 0">
                        {{destinazione.ss_avvertenze}}
                    </div>
                    <div class="destinazione-section-content" *ngIf="destinazione.ss_avvertenze.length <= 0">
                        Nessun dato presente.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>