export class UtilityService {

    generateRandomString(length = 5) {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    // https://stackoverflow.com/questions/6137986/javascript-roundoff-number-to-nearest-0-5
    roundPrice = (price: number): number => {
        const inv = 1.0 / 0.05;
        return Math.round(price * inv) / inv;
    }
}
