import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'integer' })
export class IntegerPipe implements PipeTransform {
    constructor() {
    }

    transform(content) {
        return parseInt(content);
    }
}
