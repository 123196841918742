import { Component, OnInit } from '@angular/core';
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
  selector: 'app-coronavirus',
  templateUrl: './coronavirus.component.html',
  styleUrls: ['./coronavirus.component.css']
})
export class CoronavirusComponent implements OnInit {

  constructor(private metatagsService: MetatagsService) {
      this.metatagsService.updateTitle("iViaggio - Coronavirus");
      this.metatagsService.updateDescription("Tutto quello che c'è da sapere");
      this.metatagsService.updateUrl();
  }

  ngOnInit() {
  }

}
