<section id="viaggiatori-particolari">
    <div id="viaggiatori-particolari-head">
        <div id="viaggiatori-particolari-head-container">
            
            <div id="viaggiatori-particolari-title">
                Viaggiatori particolari
            </div>

            <img src="/assets/images/img_viaggiatori_particolari_header.svg" />
        </div>
    </div>

    <div id="viaggiatori-particolari-content">
        <div id="viaggiatori-particolari-menu" class="clearfix">
            <div
                    class="viaggiatori-particolari-menu-el"
                    (click)="selectedMenu = 'viaggiatori'"
                    [ngClass]="{'viaggiatori-particolari-menu-el_active': selectedMenu == 'viaggiatori'}"
            >
                Viaggiatori con Bambini
            </div>

            <div
                    class="viaggiatori-particolari-menu-el"
                    (click)="selectedMenu = 'donne'"
                    [ngClass]="{'viaggiatori-particolari-menu-el_active': selectedMenu == 'donne'}"
            >
                Donne Gravide
            </div>

            <div
                    class="viaggiatori-particolari-menu-el"
                    (click)="selectedMenu = 'Immunodepressi'"
                    [ngClass]="{'viaggiatori-particolari-menu-el_active': selectedMenu == 'Immunodepressi'}"
            >
                Immunodepressi
            </div>

            <div
                    class="viaggiatori-particolari-menu-el"
                    (click)="selectedMenu = 'vfr'"
                    [ngClass]="{'viaggiatori-particolari-menu-el_active': selectedMenu == 'vfr'}"
            >
                VFR (Visiting Friends and Relatives)
            </div>

            <div
                    class="viaggiatori-particolari-menu-el"
                    (click)="selectedMenu = 'anziani'"
                    [ngClass]="{'viaggiatori-particolari-menu-el_active': selectedMenu == 'anziani'}"
            >
                Anziani
            </div>
        </div>

        <div id="viaggiatori-particolari-data">
            <div *ngIf="selectedMenu == 'viaggiatori'">
                <div class="vp-title">Viaggiatori con Bambini</div>

                <div class="vp-subTitle">Consigli utili</div>
                <div class="vp-text">
                    I bambini sono viaggiatori che richiedono attenzioni "speciali" sia al momento della preparazione del viaggio sia durante tutto il soggiorno all'estero e nel periodo successivo al rientro. L'attenzione deve essere maggiore quanto più è piccolo il bambino.
                </div>

                <div class="vp-subTitle">Prima del viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            effettuare per tempo tutte le vaccinazioni indicate per l'età del bambino e per il Paese di destinazione
                            (in primo luogodeveessere controllata la situazione vaccinale e , se necessario, completare ed integrare le
                            vaccinazioni già effettuate. Altre vaccinazioni dipenderanno dalla destinazione, dal tipo di viaggio e dal comportamento individuale);
                        </li>
                        <li>
                            attrezzare la farmacia di viaggio con farmaci adeguati all'età del bambino;
                        </li>
                        <li>
                            portare soluzioni reidratanti perchè nel bambino una diarrea può essere molto pericolosa e
                            determinare grave disidratazione in tempi molto brevi. (Una delle patologie più diffuse ed importanti nel bambino che viaggia
                            è la diarrea. La sua pericolosità dipende dalla quantità di liquidi persi con le scariche diarroiche e con il vomito. Dato
                            che in un bambino il volume totale di liquidi è maggiore rispetto ad un adulto, l’effetto della diarrea risulta amplificato.
                            Questo comporta che un bambino può disidratarsi in poche ore. Si raccomanda allo scopo di portarsi in viaggio soluzioni idrosaline
                            commerciali che dovranno essere sciolte in ½ litro di acqua potabile (bollita e raffreddata in caso di dubbio) e di iniziare
                            già alla prima scarica il trattamento con tali soluzioni. Se il bambino vomita in continuazione e non può assumere assolutamente
                            niente per bocca, dovrà molto probabilmente essere reidratato per via endovenosa in idonea struttura sanitaria. Nel caso di
                            diarrea di marcata entità, o di diarrea con muco e sangue nelle feci è assolutamente necessario consultare un medico.);
                        </li>
                        <li>
                            effettuare la chemioprofilassi per la malaria con farmaci e dosi adeguate per l'età ed il peso del bambino
                            ((L’Organizzazione Mondiale della Sanità sconsiglia di portare neonati e bambini piccoli in aree malariche,
                            in particolare dove vi sia trasmissione di P.falciparum clorochino-resistente.); Se non è possibile evitare il viaggio,
                            i bambini dovrebbero essere ben protetti contro le punture di zanzara. Per quanto riguarda i repellenti cutanei,
                            devono essere usati con cautela nei bambini per i potenziali danni tossici e tenendo presente le seguenti indicazioni:

                            <ul>
                                <li>
                                    Evitare di applicare prodotti ad alta concentrazione di DEET (N,Ndietil-m-toluamide > del 30%) sulla pelle dei bambini;
                                </li>
                                <li>
                                    Evitare di applicare i repellenti sulle mani e sulle parti di esse che i bambini potrebbero mettere
                                    in bocca o passare sugli occhi;
                                </li>
                                <li>
                                    Non applicare i repellenti su ferite, né sulla pelle irritata;  • Usare i repellenti con parsimonia,
                                    senza eccedere nell’uso. E’ inoltre importante che i bambini ricevano una profilassi antimalarica, se necessaria,
                                    ricordandosi che nessuna profilassi assicura una perfetta protezione e talvolta può essere difficile somministrare
                                    una dose adeguata Bambini che viaggiano a causa delle piccole quantità richieste e della mancanza di preparati pediatrici.)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Durante il viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            garantire la sicurezza in viaggio (es: cinture di sicurezza).
                        </li>
                        <li>
                            avere sempre a portata di mano bevande per evitare la disidratazione.
                        </li>
                        <li>
                            proteggere dalle punture di insetto.
                        </li>
                        <li>
                            fare attenzione all'igiene alimentare e delle bevande.
                        </li>
                        <li>
                            proteggere dai raggi solari dal colpo di sole, dal colpo di calore nei climi caldi e dal congelamento o assideramento nei climi freddi (ricordarsi che il bambino piccolo non è in grado di riferire sensazioni di troppo caldo o troppo freddo)
                        </li>
                        <li>
                            proseguire la profilassi antimalarica
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Dopo il viaggio</div>
                <div class="vp-text">
                    proseguire e completare la profilassi antimalarica
                </div>
            </div>
            <div *ngIf="selectedMenu == 'donne'">
                <div class="vp-title">Donne Gravide</div>

                <div class="vp-subTitle">Consigli utili</div>
                <div class="vp-text">
                    La gravidanza non costituisce una controindicazione ai viaggi.
                    Durante la gravidanza devono aumentare le attenzioni nella programmazione e preparazione di un viaggio.
                    In caso di gravidanza a rischio il viaggio può essere controindicato. Inoltre, poichè alcune malattie possono
                    essere più gravi durante la gravidanza (ad esempio la malaria o l'epatite da virus E), e alcune profilassi vaccinali o
                    chemioprofilassi non possono essere effettuate durante la gravidanza, è necessario valutare bene i rischi del viaggio sia
                    per la madre sia per il bambino Il soggiorno in Paesi caldi comporta un aumentato rischio di infezioni delle vie urinarie.
                    In gravidanza sono controindicati alcuni tipi di vaccini, mentre altri possono essere somministrati se la situazione epidemiologica lo
                    richiede. La malaria nella donna in stato di gravidanza aumenta il rischio di morte della madre, aborto e parto di feto morto o basso
                    peso alla nascita con possibilità di morte nel neonato. Per questo, se non è assolutamente necessario, è bene evitare un viaggio in zone
                    malariche. Se il viaggio non può essere rimandato, il medico del Servizio fornirà tutte le indicazioni sulla necessità di effettuare la
                    profilassi antimalarica e sulla scelta del farmaco.
                </div>

                <div class="vp-subTitle">Prima del viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            per i viaggi lunghi valutare la necessità di effettuare una profilassi per la Trombosi Venosa Profonda.
                        </li>
                        <li>
                            alcune vaccinazioni sono controindicate in gravidanza oppure non ci sono dati sufficienti sulla sicurezza in gravidanza.
                        </li>
                        <li>
                            alcuni farmaci per la profilassi antimalarica sono controindicati in gravidanza.
                        </li>
                        <li>
                            rifornire la farmacia di viaggio con farmaci che possono essere utilizzati in gravidanza.
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Durante il viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            fare particolare attenzione all' Igiene alimentare e delle bevande
                        </li>
                        <li>
                            la protezione dalle punture di insetto deve essere costante.
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Dopo il viaggio</div>
                <div class="vp-text">
                    Nessuna indicazione particolare
                </div>
            </div>
            <div *ngIf="selectedMenu == 'Immunodepressi'">
                <div class="vp-title">Immunodepressi</div>

                <div class="vp-subTitle">Consigli utili</div>
                <div class="vp-text">
                    L'immunodepressione dovuta a farmaci, infezioni o patologie ereditarie aumenta il rischio di manifestazioni gravi in caso di infezioni.
                    Inoltre, in alcuni casi, sono controindicate le vaccinazioni con vaccini che contengono dei patogeni vivi attenuati
                </div>

                <div class="vp-subTitle">Prima del viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            consultare il proprio medico per decidere quali, tra le vaccinazioni indicate per la destinazione scelta,
                            possono essere effettuate in sicurezza e valutare i rischi ed i benefici relativi alla propria condizione.
                        </li>
                        <li>
                            porre estrema attenzione alle misure specifiche e aspecifiche di profilassi.
                        </li>
                        <li>
                            definire una farmacia di viaggio che consideri anche possibili complicazioni o manifestazioni gravi di patologie acquisite durante il viaggio.
                        </li>
                        <li>
                            informarsi sulle strutture sanitarie presenti nel luogo di destinazione. 5. effettuare una assicurazione sanitaria adeguata.
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Durante il viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            fare particolare attenzione all' Igiene alimentare e delle bevande.
                        </li>
                        <li>
                            la protezione dalle punture di insetto deve essere costante.
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Dopo il viaggio</div>
                <div class="vp-text">
                    Nessuna indicazione particolare
                </div>
            </div>
            <div *ngIf="selectedMenu == 'vfr'">
                <div class="vp-title">VFR (Visiting Friends and Relatives)</div>

                <div class="vp-subTitle">Consigli utili</div>
                <div class="vp-text">
                    Anche chi è nato e vissuto in un Paese malarico deve prendere precauzioni contro la malaria: infatti metà
                    dei casi di malaria importati in Italia sono diagnosticati in migranti che ritornano nel loro Paese di origine
                    dopo avere soggiornato per qualche anno all'estero. Questo fenomeno è dovuto al fatto che, una volta lasciato il Paese in
                    cui la malaria è endemica, viene perso lo stato di "parziale immunizzazione" e il migrante diventa di nuovo suscettibile alle
                    forme gravi di malattia come qualsiasi soggetto che per la prima volta si espone al rischio di malaria.
                </div>

                <div class="vp-subTitle">Prima del viaggio</div>
                <div class="vp-text">
                    Consigli ai migranti che ritornano al loro Paese di origine:
                    <ul>
                        <li>
                            anche se sei nato o vissuto per anni in Paesi dove è presente la malaria ma te ne sei allontanato per un periodo
                            prolungato ricordati che devi assumere la  profilassi antimalarica quando ritorni al tuo Paese di origine.
                        </li>
                        <li>
                            ricordati anche di prevenire le punture di insetto per evitare non solo la malaria, ma anche le altre malattie
                            che vengono trasmesse dagli insetti (ad esempio dengue, Chikungunya).
                        </li>
                        <li>
                            se hai in programma di portare i tuoi bambini al tuo Paese di origine per farli conoscere ai parenti,
                            ricordati di proteggerli dalla malaria perchè il rischio di avere forme gravi di malattia nei bambini piccoli è molto elevato.
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Durante il viaggio</div>
                <div class="vp-text">
                    <ul>
                        <li>
                            fare particolare attenzione all' Igiene alimentare e delle bevande.
                        </li>
                        <li>
                            la protezione dalle punture di insetto deve essere costante.
                        </li>
                    </ul>
                </div>

                <div class="vp-subTitle">Dopo il viaggio</div>
                <div class="vp-text">
                    Nessuna indicazione particolare
                </div>
            </div>
            <div *ngIf="selectedMenu == 'anziani'">
                <div class="vp-title">Anziani</div>

                <div class="vp-subTitle">Consigli utili</div>
                <div class="vp-text">
                    Le precauzioni più importanti per un anziano, prima di intraprendere un viaggio, consistono nell’assicurarsi
                    che il tipo di viaggio sia adatto alle sue condizioni fisiche e che sia programmato un adeguato periodo di riposo,
                    specialmente all’arrivo a destinazione.
                </div>

                <div class="vp-subTitle">Prima del viaggio</div>
                <div class="vp-text">
                    La presenza di malattie croniche quali:
                    <ul>
                        <li>
                            ipertensione arteriosa
                        </li>
                        <li>
                            cardiopatie
                        </li>
                        <li>
                            alterazioni del ritmo cardiaco
                        </li>
                        <li>
                            diabete mellito
                        </li>
                        <li>
                            alterazioni della funzionalità tiroidea
                        </li>
                        <li>
                            insufficienza epatica
                        </li>
                        <li>
                            insufficienza renale cronica
                        </li>
                        <li>
                            epilessia
                        </li>
                        <li>
                            depressione
                        </li>
                    </ul>

                    non è una controindicazioni assoluta all’assunzione di una profilassi antimalarica. Tali patologie, tuttavia, richiedono, una terapia farmacologica a lungo termine con possibile insorgenza di
                    interazione tra i farmaci che il paziente assume regolarmente per la sua patologia ed i
                    farmaci antimalarici. Inoltre la funzionalità di alcuni organi è spesso alterata dalla patologia cronica di base o dai
                    farmaci assunti; per cui è possibile che la contemporanea somministrazione di antimalarici provochi una diminuzioni della
                    loro attività o una più facile comparsa di effetti indesiderati. E’ importante quindi che i soggetti affetti da malattie
                    croniche segnalino, quando si rivolgono al proprio Medico o ai Centro di Medicina dei Viaggi, il tipo di malattia di
                    cui sono affetti e l’eventuale assunzione di farmaci per poter personalizzare quanto più possibile la profilassi antimalarica nel
                    rispetto comunque delle linee–guida internazionali. LA FARMACIA DI VIAGGIO: scorta di farmaci da tenere nel bagaglio a mano (nel caso di
                    smarrimento del bagaglio che viene spedito); ricordarsi di fare una scorta di tutti i farmaci abituali sufficienti per tutto il periodo
                    di soggiorno all'estero ; fornirsi di preparati per la reidratazione orale in caso di diarrea del viaggiatore .
                    <br />
                    <br />
                    <strong>CERTIFICAZIONI SANITARIE E ASSICURAZIONE</strong>:
                    in caso di patologie croniche è utile fornirsi di certificazione medica che indichi anche il trattamento in corso ;
                    stipulare una assicurazione sanitaria internazionale ; è necessario informarsi presso le compagnie aeree sulle modalità per
                    il trasporto di materiale sanitario come siringhe per insulina.
                </div>

                <div class="vp-subTitle">Durante il viaggio</div>
                <div class="vp-text">
                    Viaggiatore diabetico: le variazioni di fuso orario e degli orari dei pasti potrebbero costituire un problema
                    per il paziente diabetico in terapia insulinica. Utile un monitoraggio stretto dei livelli glicemici.
                    viaggiatore con cardiopatie o patologie polmonari croniche: il viaggio aereo è sconsigliato per i soggetti con
                    recente infarto del miocardio, angina instabile, scompenso cardiaco congestizio, recenti interventi chirurgici toracici,
                    insufficienza respiratoria con ipossiemia a riposo, ipertensione arteriosa grave non controllata farmacologicamente.
                </div>

                <div class="vp-subTitle">Dopo il viaggio</div>
                <div class="vp-text">
                    Nessuna indicazione particolare
                </div>
            </div>
        </div>
    </div>
</section>