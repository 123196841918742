import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WebsiteComponent} from './website.component';
import {IndexComponent} from './main/index/index.component';
import {NotFoundComponent} from './main/not-found/not-found.component';
import {CentriVaccinazioneComponent} from "./main/centri-vaccinazione/centri-vaccinazione.component";
import {DestinazioniComponent} from "./main/destinazioni/destinazioni.component";
import {ViaggiatoriParticolariComponent} from "./main/viaggiatori-particolari/viaggiatori-particolari.component";
import {RischioMalariaPrevenzioneComponent} from "./main/rischio-malaria-prevenzione/rischio-malaria-prevenzione.component";
import {DieciRegoleComponent} from "./main/dieci-regole/dieci-regole.component";
import {DestinazioneComponent} from "./main/destinazioni/destinazione/destinazione.component";
import {CentroVaccinazioneComponent} from "./main/centri-vaccinazione/centro-vaccinazione/centro-vaccinazione.component";
import {CoronavirusComponent} from "./main/coronavirus/coronavirus.component";

const websiteRoutes: Routes = [
    {path: '', component: WebsiteComponent, children: [
        {path: '', component: IndexComponent},
        {path: 'destinazioni', component: DestinazioniComponent},
        {path: 'destinazioni/:destinazione', component: DestinazioneComponent},
        {path: 'centri-vaccinazione', component: CentriVaccinazioneComponent},
        {path: 'centri-vaccinazione/:centro', component: CentroVaccinazioneComponent},
        {path: 'viaggiatori-particolari', component: ViaggiatoriParticolariComponent},
        {path: 'rischio-malaria-e-prevenzione', component: RischioMalariaPrevenzioneComponent},
        {path: 'dieci-regole-del-viaggiatore', component: DieciRegoleComponent},
        {path: 'covid-19', component: CoronavirusComponent},
        {path: '404', component: NotFoundComponent},
        {path: '**', redirectTo: '404'}
    ]}
];

@NgModule({
    imports: [
        RouterModule.forChild(websiteRoutes)
    ],
    exports: [RouterModule],
    providers: []
})
export class WebsiteRoutingModule {

}
