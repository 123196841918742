import { Component, OnInit } from '@angular/core';
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-rischio-malaria-prevenzione',
    templateUrl: './rischio-malaria-prevenzione.component.html',
    styleUrls: ['./rischio-malaria-prevenzione.component.css']
})
export class RischioMalariaPrevenzioneComponent implements OnInit {

    selectedMenu = 'a';

    constructor(
        private metatagsService: MetatagsService
    ) { }

    ngOnInit() {
        this.metatagsService.updateTitle("iViaggio - Rischio malaria e prevenzione");
        this.metatagsService.updateDescription("Scopri quali sono i rischi derivanti dal viaggio e come prevenirli attraverso le indicazioni fornite");
        this.metatagsService.updateUrl();
    }

}
