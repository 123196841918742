<div id="baseHeader" [style.backgroundColor]="backgroundColor">
    <div id="header" class="clearfix">
        <div id="header_image" [routerLink]="['/']">
            <img src="/assets/images/logotype.svg" />
        </div>
        <nav id="header_menu">
            <div class="header-element" routerLinkActive="header-element-active" [routerLink]="['/', 'covid-19']">
                COVID-19 <br /> (Coronavirus)

                <div class="whiteBox" [style.display]="active == '/covid-19' ? 'block' : 'none'"></div>
            </div>
            <div class="header-element" routerLinkActive="header-element-active" [routerLink]="['/', 'dieci-regole-del-viaggiatore']">
                Le 10 regole <br /> del viaggiatore

                <div class="whiteBox" [style.display]="active == '/dieci-regole-del-viaggiatore' ? 'block' : 'none'"></div>
            </div>
            <div class="header-element" routerLinkActive="header-element-active" [routerLink]="['/', 'rischio-malaria-e-prevenzione']">
                Rischio malaria <br /> e prevenzione

                <div class="whiteBox" [style.display]="active == '/rischio-malaria-e-prevenzione' ? 'block' : 'none'"></div>
            </div>
            <div class="header-element" routerLinkActive="header-element-active" [routerLink]="['/', 'viaggiatori-particolari']">
                Viaggiatori <br /> particolari

                <div class="whiteBox" [style.display]="active == '/viaggiatori-particolari' ? 'block' : 'none'"></div>
            </div>
            <div class="header-element" routerLinkActive="header-element-active" [routerLink]="['/', 'centri-vaccinazione']">
                Centri di <br /> vaccinazione

                <div class="whiteBox" [style.display]="active.startsWith('/centri-vaccinazione') ? 'block' : 'none'"></div>
            </div>
            <div class="header-element" routerLinkActive="header-element-active" [routerLink]="['/', 'destinazioni']">
                Dove vuoi <br /> andare?

                <div class="whiteBox" [style.display]="active == '/destinazioni' ? 'block' : 'none'"></div>
            </div>
        </nav>
        <nav id="header_menu_mobile">
            <lottie-animation-view
                    [options]="lottieConfig"
                    [width]="25"
                    [height]="25"
                    (animCreated)="handleAnimation($event)"
                    (click)="openMenu()"
            >
            </lottie-animation-view>

            <div id="header_menu_mobile_content" [@mobileMenuAnimationStatus]="mobileMenuAnimationStatus">
                <div id="header_mobile_menu">
                    <div class="header_mobile_menu_el clearfix" [routerLink]="['/', 'destinazioni']">
                        <div>
                            <img src="/assets/images/icon_dove_vuoi_andare.svg" />
                        </div>
                        <div>
                            Dove vuoi andare?
                        </div>
                    </div>
                    <div class="header_mobile_menu_el clearfix" [routerLink]="['/', 'centri-vaccinazione']">
                        <div>
                            <img src="/assets/images/icon_centri_vaccinazione.svg" />
                        </div>
                        <div>
                            Centri di vaccinazione
                        </div>
                    </div>
                    <div class="header_mobile_menu_el clearfix" [routerLink]="['/', 'viaggiatori-particolari']">
                        <div>
                            <img src="/assets/images/icon_viaggiatori_particolari.svg" />
                        </div>
                        <div>
                            Viaggiatori particolari
                        </div>
                    </div>
                    <div class="header_mobile_menu_el clearfix" [routerLink]="['/', 'rischio-malaria-e-prevenzione']">
                        <div>
                            <img src="/assets/images/icon_rischiomalaria.svg" />
                        </div>
                        <div>
                            Rischio malaria e prevenzione
                        </div>
                    </div>
                    <div class="header_mobile_menu_el clearfix" [routerLink]="['/', 'dieci-regole-del-viaggiatore']">
                        <div>
                            <img src="/assets/images/icon_dieci_regole.svg" />
                        </div>
                        <div>
                            Le 10 regole del viaggiatore
                        </div>
                    </div>
                    <div class="header_mobile_menu_el clearfix" [routerLink]="['/', 'covid-19']">
                        <div>
                            <img src="/assets/images/corona_virus.svg" />
                        </div>
                        <div>
                            COVID-19 (Coronavirus)
                        </div>
                    </div>
                </div>
                <div id="header_mobile_download">
                    <div>
                        Scarica iViaggio sul tuo smartphone
                    </div>
                    <div id="header_mobile_download_buttons">
                        <a href="#">
                            <div class="clearfix">
                                <img src="/assets/images/googleplay_store_icon.svg" />
                            </div>
                        </a>
                        <a href="#">
                            <div class="clearfix">
                                <img src="/assets/images/app_store_icon.svg" />
                            </div>
                        </a>
                    </div>
                </div>
                <div id="header_mobile_credits">
                    Realizzata con fondi del Progetto Obiettivo PSN 2016 az 1.6 “ iViaggio l’APP del viaggiatore - viaggiando in sicurezza” <br />
                    Responsabile Scientifico : <strong>Prof. Alberto Firenze</strong>
                </div>
                <div id="header_mobile_credits_names">
                    <img src="/assets/images/logo_regione_sic.png" />
                    <img src="/assets/images/logo_uni.png" />
                </div>
                <div id="header_mobile_copy">
                    Copyright © 2019 - Tutti i diritti riservati Powered by OB Science S.r.l.
                </div>
            </div>
        </nav>
    </div>
</div>