import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatnumber' })
export class FormatNumberPipe implements PipeTransform {
    constructor() {
    }

    transform(content) {
        return content.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}
