<div id="footer">
    <div id="footer-holder">
        <div id="realized-how">
            Realizzata con fondi del Progetto Obiettivo PSN 2016 az 1.6 “ iViaggio l’APP del viaggiatore - viaggiando in sicurezza” <br />
            Responsabile Scientifico : <strong>Prof. Alberto Firenze</strong>
        </div>

        <div id="realized-images">
            <img src="/assets/images/logo_regione_sic.png" />
            <img src="/assets/images/logo_uni.png" />
        </div>

        <div id="footer-copy">
            Copyright © 2019 - Tutti i diritti riservati - Powered by OB Science S.r.l.
        </div>
    </div>
</div>