import {Component, OnDestroy, OnInit} from '@angular/core';
import {Destinazioni} from "../../../../Models/destinazioni.model";
import {Subscription} from "rxjs";
import {CentriVaccinazione} from "../../../../Models/CentriVaccinazioni.model";
import {CentriVaccinazioneApiService} from "../../../../Services/API/centri-vaccinazione-api.service";
import {Router} from "@angular/router";
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-centri-vaccinazione',
    templateUrl: './centri-vaccinazione.component.html',
    styleUrls: ['./centri-vaccinazione.component.css']
})
export class CentriVaccinazioneComponent implements OnInit, OnDestroy {

    centriVaccinazione: CentriVaccinazione[] = [];
    centriVaccinazioneBase: CentriVaccinazione[];
    centriVaccinazioneSub: Subscription;
    hasLoaded = false;

    public constructor (
        private centriVaccinazioneApiService: CentriVaccinazioneApiService,
        private router: Router,
        private metatagsService: MetatagsService
    ) {
        this.metatagsService.updateTitle("iViaggio - Centri di vaccinazione");
        this.metatagsService.updateDescription("Tramite iViaggio potrai tovare la struttura più vicina. Avrai inoltre la possibilità di contattare telefonicamente il centro ed ottenere le indicazioni stradali");
        this.metatagsService.updateUrl();
    }

    ngOnInit() {
        this.centriVaccinazioneSub = this.centriVaccinazioneApiService.getCentriVaccinazione().subscribe((ds: Destinazioni[]) => {
            if(ds.length > 0) {
                this.hasLoaded = true;
                this.centriVaccinazione = JSON.parse(JSON.stringify(ds));
                this.centriVaccinazioneBase = JSON.parse(JSON.stringify(ds));
            }
        });
    }

    onSearch(query) {
        this.centriVaccinazione = [];
        for(const i in this.centriVaccinazioneBase) {
            if (this.centriVaccinazioneBase.hasOwnProperty(i)) {
                let data = JSON.parse(JSON.stringify(this.centriVaccinazioneBase[i]));
                data.localita = this.centriVaccinazioneBase[i].localita.filter(item => {
                    return item.toLowerCase().startsWith(query.toLowerCase())
                });

                if(data.localita.length > 0) {
                    this.centriVaccinazione.push(data);
                }
            }
        }
    }

    onSelect(regione: string, localita: string){
        this.router.navigate(['/', 'centri-vaccinazione', localita]);
    }

    ngOnDestroy(){
        if (!this.centriVaccinazioneSub.closed) {
            this.centriVaccinazioneSub.unsubscribe();
        }
    }

}
