import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from './notifications/notifications.component';
import {TooltipDirective} from '../../Directives/tooltip.directive';

@NgModule({
    declarations: [
        NotificationsComponent,
        TooltipDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CommonModule,
        NotificationsComponent,
        TooltipDirective
    ],
    providers: [
    ]
})
export class SharedModule {}

