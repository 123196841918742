<section id="malaria-prevenzione">
    <div id="malaria-prevenzione-head">
        <div id="malaria-prevenzione-head-container">
            <div id="malaria-prevenzione-title">
                Rischio malaria e prevenzione
            </div>
            <div id="malaria-prevenzione-select">
                Seleziona una tipologia
            </div>

            <div id="malaria-prevenzione-menu">
                <div class="clearfix" [ngClass]="{'malaria-prevenzione-menu-selected' : selectedMenu == 'a'}" (click)="selectedMenu = 'a'">
                    <div>tipo</div> <div>A</div>
                </div>
                <div class="clearfix" [ngClass]="{'malaria-prevenzione-menu-selected' : selectedMenu == 'b'}" (click)="selectedMenu = 'b'">
                    <div>tipo</div> <div>B</div>
                </div>
                <div class="clearfix" [ngClass]="{'malaria-prevenzione-menu-selected' : selectedMenu == 'c'}" (click)="selectedMenu = 'c'">
                    <div>tipo</div> <div>C</div>
                </div>
                <div class="clearfix" [ngClass]="{'malaria-prevenzione-menu-selected' : selectedMenu == 'd'}" (click)="selectedMenu = 'd'">
                    <div>tipo</div> <div>D</div>
                </div>
            </div>
        </div>
    </div>

    <div id="malaria-prevenzione-body">
        <div *ngIf="selectedMenu == 'a'">
            <div class="mp-box">
                <div class="mp-title">Rischio Malaria</div>
                <div class="mp-data">Rischio molto limitato di trasmissione</div>
                <div class="mp-title">TIPO DI PREVENZIONE</div>
                <div class="mp-data">Solo protezione alle punture di zanzare</div>
            </div>

            <div class="mp-extra">
                <div>
                    <img src="/assets/images/icon_rischiomalaria.svg" />
                </div>
                <div>
                    In alternativa, quando viaggiano in aree rurali con basso rischio di infezione da malaria,
                    la prevenzione delle punture di zanzare può essere combinata con trattamento di emergenza stand-by (SBET).In alcune aree con malaria multiresistente,
                    la chemioprofilassi meflochina non è più raccomandata. Al momento sono la Cambogia, la Thailandia, e sud-est Myanmar.
                </div>
            </div>
        </div>
        <div *ngIf="selectedMenu == 'b'">
            <div class="mp-box">
                <div class="mp-title">Rischio Malaria</div>
                <div class="mp-data">Rischio di trasmissione da P. vivax soltanto</div>
                <div class="mp-title">TIPO DI PREVENZIONE</div>
                <div class="mp-data">Protezione alle punture di zanzare + chemioprofilassi con clorochinaa</div>
            </div>

            <div class="mp-extra">
                <div>
                    <img src="/assets/images/icon_rischiomalaria.svg" />
                </div>
                <div>
                    In alcune aree con malaria multiresistente, la chemioprofilassi meflochina non è più raccomandata.
                    Al momento sono la Cambogia, la Thailandia, e sud-est Myanmar.
                </div>
            </div>
        </div>
        <div *ngIf="selectedMenu == 'c'">
            <div class="mp-box">
                <div class="mp-title">Rischio Malaria</div>
                <div class="mp-data"> Rischio di trasmissione da P. falciparum con resistenza alla clorochina e sulfadossina-pirimetamina</div>
                <div class="mp-title">TIPO DI PREVENZIONE</div>
                <div class="mp-data">
                    Protezione alle punture di zanzare + chemioprofilassi con:atovaquone–proguanil odoxiciclina e sulfadoxina–pirimetamina
                    omeflochina(scegliere in base agli effetti indesiderati rilevati e controindicazioni)
                </div>
            </div>
        </div>
        <div *ngIf="selectedMenu == 'd'">
            <div class="mp-box">
                <div class="mp-title">Rischio Malaria</div>
                <div class="mp-data">Rischio di P. falciparum in combinazione con multi resistenza</div>
                <div class="mp-title">TIPO DI PREVENZIONE</div>
                <div class="mp-data">
                    Protezione alle punture di zanzare + chemioprofilassi con:atovaquone–  proguanil odoxiciclina e sulfadoxina –
                    pirimetamina omeflochina(scegliere in base a farmacoresistenza, effetti collaterali e controindicazioni)
                </div>
            </div>
        </div>
    </div>

</section>