import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {MetatagsService} from "../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
    constructor(
        private router: Router,
        private metatagsService: MetatagsService
    ) {
        this.metatagsService.updateTitle("iViaggio");
        this.metatagsService.updateDescription("Qualunque sia la tua meta, parti con iViaggio");
        this.metatagsService.updateUrl();

        this.metatagsService.updateOG("og:type", "website");
        this.metatagsService.updateOG("og:image", metatagsService.baseUrl("/assets/images/social_preview.jpg"));
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            window.scrollTo(0, 0)
        });
    }

}
