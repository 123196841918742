import { Component, OnInit } from '@angular/core';
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-viaggiatori-particolari',
    templateUrl: './viaggiatori-particolari.component.html',
    styleUrls: ['./viaggiatori-particolari.component.css']
})
export class ViaggiatoriParticolariComponent implements OnInit {

    selectedMenu = 'viaggiatori';

    constructor(
        private metatagsService: MetatagsService
    ) {
        this.metatagsService.updateTitle("iViaggio - Viaggiatori particolari");
        this.metatagsService.updateDescription("iViaggio fornisce diversi consigli utili dedicati a viaggiatori con particolari condizioni fisiche, fornendo così controindicazioni e informazioni su eventuali rischi derivanti dal viaggio pianificato.");
        this.metatagsService.updateUrl();
    }

    ngOnInit() {
    }

}

