import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {SharedModule} from '../Shared/shared.module';
import {WebsiteComponent} from './website.component';
import {WebsiteRoutingModule} from './website-routing.module';
import {MainComponent} from './main/main.component';
import {IndexComponent} from './main/index/index.component';
import {FooterComponent} from './footer/footer.component';
import {PipesModule} from '../../Pipes/pipes.module';
import {NotFoundComponent} from './main/not-found/not-found.component';
import {FormsModule} from '@angular/forms';
import {LottieAnimationViewModule} from 'ng-lottie';
import {CentriVaccinazioneComponent} from "./main/centri-vaccinazione/centri-vaccinazione.component";
import {DestinazioniComponent} from "./main/destinazioni/destinazioni.component";
import {ViaggiatoriParticolariComponent} from "./main/viaggiatori-particolari/viaggiatori-particolari.component";
import {RischioMalariaPrevenzioneComponent} from "./main/rischio-malaria-prevenzione/rischio-malaria-prevenzione.component";
import {DieciRegoleComponent} from "./main/dieci-regole/dieci-regole.component";
import {DestinazioneComponent} from "./main/destinazioni/destinazione/destinazione.component";
import {CentroVaccinazioneComponent} from "./main/centri-vaccinazione/centro-vaccinazione/centro-vaccinazione.component";
import {CoronavirusComponent} from "./main/coronavirus/coronavirus.component";

const declarations = [
    WebsiteComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    IndexComponent,
    NotFoundComponent,
    CentriVaccinazioneComponent,
    DestinazioniComponent,
    ViaggiatoriParticolariComponent,
    CentroVaccinazioneComponent,
    RischioMalariaPrevenzioneComponent,
    DieciRegoleComponent,
    DestinazioneComponent,
    CoronavirusComponent
];

@NgModule({
    declarations: declarations,
    exports: [
        WebsiteComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        WebsiteRoutingModule,
        PipesModule,
        FormsModule,
        LottieAnimationViewModule.forRoot()
    ]
})
export class WebsiteModule {}
