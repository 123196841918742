import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../../../Services/Shared/notification.service';
import {Subscription} from 'rxjs';
import {notificationsAnimations} from './notifications.animations';
import {NavigationEnd, Router} from '@angular/router';
import {ScreenSizeInterface, ScreenSizeService} from '../../../Services/Shared/screenSize.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    animations: notificationsAnimations
})
export class NotificationsComponent implements OnInit, OnDestroy {
    notificationsStack: {message: string, type: string, title: string}[];
    subscription: Subscription;
    routerSubscription: Subscription;
    screenSizeSubscription: Subscription;
    timeout = null;
    top = '';
    screenSize: ScreenSizeInterface;

    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private screenSizeService: ScreenSizeService
    ) { }

    ngOnInit() {
        this.notificationsStack = this.notificationService.getStack();
        this.subscription = this.notificationService.notifications.subscribe((notifications: {message: string, type: string, title: string}[]) => {
            if (notifications.length > 1) {
                notifications.splice(0, 1);
            }

            this.notificationsStack = notifications;
            if (this.timeout) {
               clearTimeout(this.timeout);
            }

            // Closing notification after 5 seconds
            // this.timeout = setTimeout(() => {
            //     this.notificationsStack = [];
            // }, 5000);
        });

        this.screenSize = this.screenSizeService.getInitialSize();
        this.screenSizeSubscription = this.screenSizeService.size.subscribe((size: ScreenSizeInterface) => {
            this.screenSize = size;
        });
    }

    unStack(index: number) {
        this.notificationService.unStack(index);
    }

    ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }

        if (this.routerSubscription && !this.routerSubscription.closed) {
            this.routerSubscription.unsubscribe();
        }

        if (this.screenSizeSubscription && !this.screenSizeSubscription.closed) {
            this.screenSizeSubscription.unsubscribe();
        }
    }
}
