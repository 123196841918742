import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiTokenInterceptor} from './api-token.interceptor';
import {ApiErrorInterceptor} from './api-error.interceptor';
import {DestinazioniApiService} from './destinazioni-api.service';
import {CentriVaccinazioneApiService} from "./centri-vaccinazione-api.service";

@NgModule({
    declarations: [
    ],
    imports: [
        HttpClientModule
    ],
    providers: [
        DestinazioniApiService,
        CentriVaccinazioneApiService,
        {
            provide     : HTTP_INTERCEPTORS,
            useClass    : ApiTokenInterceptor,
            multi       : true
        },
        {
            provide     : HTTP_INTERCEPTORS,
            useClass    : ApiErrorInterceptor,
            multi       : true
        }
    ]
})
export class ApiModule { }
