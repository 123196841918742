import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class NotificationService {
    notifications = new Subject<{}>();
    private notificationsStack: {message: string, type: string, title: string}[] = [];

    TYPE_SUCCESS = 'success';
    TYPE_ERROR = 'error';
    TYPE_INFO = 'info';
    TYPE_WARNING = 'warning';

    constructor(
    ) { }

    getStack() {
        return this.notificationsStack;
    }

    unStack(index: number) {
        this.notificationsStack.splice(index, 1);
        this.next();
        return this;
    }

    clear() {
        this.notificationsStack = [];
        this.next();
        return this;
    }

    send(message: string, type?: string) {
        let title = 'Successo';

        switch (type) {
            case this.TYPE_SUCCESS:
                title = "Successo";
                break;
            case this.TYPE_ERROR:
                title = "Errore";
                break;
            case this.TYPE_INFO:
                title = "Informazione";
                break;
            case this.TYPE_WARNING:
                title = "Attenzione";
                break;
        }

        this.notificationsStack.push({
            message: message,
            title: title,
            type: type || this.TYPE_SUCCESS
        });

        this.next();
        return this;
    }

    private next() {
        this.notifications.next(this.notificationsStack);
    }
}
