<section id="centri-vaccinazione">
    <div id="centri-vaccinazione-head">
        <div id="centri-vaccinazione-head-container">

            <img src="/assets/images/header_dot_shadow.svg" />
            <div id="centri-vaccinazione-title">
                Trova il centro <br /> di vaccinazione più vicino.
            </div>

            <div id="centri-vaccinazione-head-text">Dove ti trovi?</div>
            <div id="centri-vaccinazione-head-input">
                <img src="/assets/images/icon_centri_vaccinazione.svg" />
                <input type="text" class="form-control" (input)="onSearch($event.target.value)" placeholder="Cerca qui...">
            </div>
        </div>
    </div>

    <div id="countriesList">
        <img id="loader" src="/assets/images/loader.svg" *ngIf="!hasLoaded" />
        <img id="no-results" src="/assets/images/no_result_img.svg" *ngIf="hasLoaded && centriVaccinazione.length <= 0" />
        <div class="country-box" *ngFor="let regioni of centriVaccinazione">
            <div class="country-head" [style.display]="regioni.localita.length == 0 ? 'none' : 'block'">{{regioni.regione}}</div>
            <div class="country-element" *ngFor="let localita of regioni.localita" (click)="onSelect(regioni.regione, localita)">{{localita}}</div>
        </div>
    </div>
</section>