import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CentriVaccinazione, CentroVaccinazione} from "../../../../../Models/CentriVaccinazioni.model";
import {CentriVaccinazioneApiService} from "../../../../../Services/API/centri-vaccinazione-api.service";
import {MetatagsService} from "../../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-centro-vaccinazione',
    templateUrl: './centro-vaccinazione.component.html',
    styleUrls: ['./centro-vaccinazione.component.css']
})
export class CentroVaccinazioneComponent implements OnInit, OnDestroy {

    centro: string;
    centriList: CentroVaccinazione[];
    centriData: {regione: string, localita: string} = {regione: '', localita: ''};
    private sub: any;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private centriVaccinazioneApi: CentriVaccinazioneApiService,
        private metatagsService: MetatagsService,
    ) { }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.centro = params['centro'];
            this.metatagsService.updateTitle("iViaggio - Centri di vaccinazione presso: " + this.centro);
            this.metatagsService.updateDescription("Lista dei centri di vaccinazione presso: " + this.centro);
            this.metatagsService.updateUrl();

            if(this.centro.length > 0) {
                // Find centro
                this.centriVaccinazioneApi.getCentri(this.centro).subscribe((data:CentroVaccinazione[])=> {
                    this.centriList = data;
                    if(this.centriList.length <= 0) {
                        this.router.navigate(['404']);
                    } else {
                        this.centriData = {
                            regione: this.centriList[0].regione,
                            localita: this.centriList[0].localita
                        };
                    }
                });
            } else {
                this.router.navigate(['404']);
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
