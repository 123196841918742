<section id="centri-vaccinazione">
    <div id="centri-vaccinazione-head">
        <div id="centri-vaccinazione-head-container">
            <img src="/assets/images/header_dot_shadow.svg" />
            <div id="centri-vaccinazione-spot">
                Centri di vaccinazione a:
            </div>
            <div id="centri-vaccinazione-title">
                <div>{{centriData.localita}}</div>
                <div>{{centriData.regione}}</div>
            </div>
        </div>
    </div>

    <div id="countriesList">
        <div class="list-centri" *ngFor="let centro of centriList">
            <div class="list-centri-name">{{centro.nome}}</div>
            <div class="list-centri-address">{{centro.indirizzo}}</div>
            <div class="list-centri-data clearfix">
                <div>
                    {{centro.telefono}}
                </div>
                <a target="_blank" href="https://www.google.com/maps/search/?api=1&query={{centro.lat}},{{centro.lng}}">
                    <div>
                       Raggiungi
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>