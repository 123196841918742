import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class DestinazioniApiService {

    constructor(
        protected httpClient: HttpClient
    ) { }

    getDestinazioniList() {
        return this.httpClient.get(environment.API_URL + '/destinazioni');
    }

    getDestinazione(id: number) {
        return this.httpClient.get(environment.API_URL + '/destinazioni/' + id);
    }
}
