import { Component, OnInit } from '@angular/core';
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

    public constructor (
        private metatagsService: MetatagsService
    ) {}

    ngOnInit() {
        this.metatagsService.updateTitle("iViaggio - Parti sereno con iViaggio.");
        this.metatagsService.updateDescription("Qualunque sia la tua meta, parti con iViaggio.");
        this.metatagsService.updateUrl();
    }
}
