import { Component, OnInit } from '@angular/core';
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-dieci-regole',
    templateUrl: './dieci-regole.component.html',
    styleUrls: ['./dieci-regole.component.css']
})
export class DieciRegoleComponent implements OnInit {

    constructor(
        private metatagsService: MetatagsService
    ) {
        this.metatagsService.updateTitle("iViaggio - Le 10 regole del viaggiatore");
        this.metatagsService.updateDescription("iViaggio mette a disposizione del viaggiatore le 10 regole fondamentali per partire in sicurezza verso tutte le mete internazionali.");
        this.metatagsService.updateUrl();
    }

    ngOnInit() {
    }

}