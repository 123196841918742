import { NgModule } from '@angular/core';
import { EscapeHtmlPipe } from './html.pipe';
import {EscapeUrlPipe} from './url.pipe';
import {EscapeStylePipe} from './style.pipe';
import {IntegerPipe} from "./integer.pipe";
import {FormatNumberPipe} from "./format-number.pipe";

const data = [
    EscapeHtmlPipe,
    EscapeUrlPipe,
    EscapeStylePipe,
    IntegerPipe,
    FormatNumberPipe
];

@NgModule({
    declarations: data,
    exports: data
})
export class PipesModule {}

