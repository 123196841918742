<div id="app-container">
    <header>
        <app-header></app-header>
    </header>

    <main id="app-content">
        <div id="app-main">
            <app-notifications></app-notifications>

            <app-main></app-main>
        </div>
    </main>

    <footer>
        <app-footer></app-footer>
    </footer>
</div>

