<section id="home">
    <div id="home-head-bg">
        <img src="/assets/images/header_dot_map.svg" />
    </div>
    <div id="home-logo">
        <div id="home-logo-box">
            <img src="/assets/images/logo_big.svg" />
            <div id="home-logo-text">
                Per chi desidera viaggiare sicuro.
            </div>
        </div>
    </div>
    <div id="home-button">
        <div class="clearfix" id="home-button-wheretogo" [routerLink]="['/', 'destinazioni']">
            <div>
                <img src="/assets/images/icon_dove_vuoi_andare.svg" />
            </div>
            <div>
                Dove vuoi andare?
            </div>
        </div>
    </div>
</section>
<section id="home-text">
    <div id="home-text-box" class="clearfix">
        <div>
            Parti sereno con iViaggio.
        </div>
        <div>
            Qualunque sia la tua meta, parti con iViaggio. Conoscerai la situazione sanitaria del Paese dove ti recherai,
            le vaccinazioni raccomandate e le regole del perfetto viaggiatore. La scelta migliore per viaggi più sereni
        </div>
    </div>
</section>
<section id="home-menu-bottom">
    <div id="home-menu-bottom-title">
        Strumenti da consultare
    </div>
    <div id="home-menu-bottom-holder" class="clearfix">
        <div class="home-menu-element" [routerLink]="['/', 'centri-vaccinazione']">
            <img src="/assets/images/icon_centri_vaccinazione.svg" />
            <div>
                Centri di <br /> vaccinazione
            </div>
        </div>
        <div class="home-menu-space"></div>
        <div class="home-menu-element" [routerLink]="['/', 'viaggiatori-particolari']">
            <img src="/assets/images/icon_viaggiatori_particolari.svg" />
            <div>
                Viaggiatori <br /> particolari
            </div>
        </div>
        <div class="home-menu-element" [routerLink]="['/', 'rischio-malaria-e-prevenzione']">
            <img src="/assets/images/icon_rischiomalaria.svg" />
            <div>
                Rischio malaria <br /> e prevenzione
            </div>
        </div>
        <div class="home-menu-space"></div>
        <div class="home-menu-element" [routerLink]="['/', 'dieci-regole-del-viaggiatore']">
            <img src="/assets/images/icon_dieci_regole.svg" />
            <div>
                Le 10 regole <br /> del viaggiatore
            </div>
        </div>
    </div>
</section>