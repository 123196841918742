import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class ScreenSizeService {
    size = new Subject<ScreenSizeInterface>();
    private siteObject: ScreenSizeInterface;

    constructor () {
        this.siteObject = {
            width: 0,
            isMobile: false
        };

        window.onresize = () => {
            this.siteObject.width = this.getCurrentSize();
            this.siteObject.isMobile = this.siteObject.width <= 768;
            this.size.next(this.siteObject);
        };
    }

    getInitialSize(): ScreenSizeInterface {
        this.siteObject.width = this.getCurrentSize();
        this.siteObject.isMobile = this.siteObject.width <= 768;

        return this.siteObject;
    }

    getCurrentSize() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
}

export interface ScreenSizeInterface {
    width: number;
    isMobile: boolean;
}
