import {Injectable} from '@angular/core';
import { Title ,Meta} from '@angular/platform-browser';

@Injectable()
export class MetatagsService {

    constructor(
        private metaHandler: Meta,
        private titleHandler: Title
    ) {

    }

    updateMeta(metaName: string, metaContent: string) {
        this.metaHandler.updateTag({ name: metaName, content: metaContent});
    }

    updateOG(property: string, content: string) {
        this.metaHandler.updateTag({ property: property, content: content});
    }

    updateTitle(title: string) {
        this.titleHandler.setTitle(title);
        this.metaHandler.updateTag({ name: 'title', content: title});
        this.metaHandler.updateTag({ property: 'og:title', content: title});
    }

    updateDescription(description: string) {
        this.metaHandler.updateTag({ name: 'description', content: description});
        this.metaHandler.updateTag({ property: 'og:description', content: description});
    }

    updateUrl() {
        this.metaHandler.updateTag({ property: 'og:url', content: location.href});
    }

    baseUrl(url: string) {
        return document.location.protocol + '//' + document.location.hostname + url
    }
}
