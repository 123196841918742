import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Destinazioni} from "../../../../Models/destinazioni.model";
import {DestinazioniApiService} from "../../../../Services/API/destinazioni-api.service";
import {MetatagsService} from "../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-destinazioni',
    templateUrl: './destinazioni.component.html',
    styleUrls: ['./destinazioni.component.css']
})
export class DestinazioniComponent implements OnInit, OnDestroy {

    destinazioni: Destinazioni[] = [];
    destinazioniBase: Destinazioni[];
    destinazioniSub: Subscription;
    hasLoaded = false;

    public constructor (
        private destinazioniApiService: DestinazioniApiService,
        private metatagsService: MetatagsService
    ) {
        this.metatagsService.updateTitle("iViaggio - Dove vuoi andare?");
        this.metatagsService.updateDescription("Cerca la tua meta e parti informato");
        this.metatagsService.updateUrl();
    }

    ngOnInit() {
        this.destinazioniSub = this.destinazioniApiService.getDestinazioniList().subscribe((ds: Destinazioni[]) => {
            if(ds.length > 0) {
                this.hasLoaded = true;
                this.destinazioni = JSON.parse(JSON.stringify(ds));
                this.destinazioniBase = JSON.parse(JSON.stringify(ds));
            }
        });
    }

    onSearch(query) {
        this.destinazioni = [];
        for(const i in this.destinazioniBase) {
            if (this.destinazioniBase.hasOwnProperty(i)) {
                let data = JSON.parse(JSON.stringify(this.destinazioniBase[i]));
                data.nazioni = this.destinazioniBase[i].nazioni.filter(item => {
                    return item.nome.toLowerCase().startsWith(query.toLowerCase())
                });

                if(data.nazioni.length > 0) {
                    this.destinazioni.push(data);
                }
            }
        }
    }

    ngOnDestroy(){
        if (!this.destinazioniSub.closed) {
            this.destinazioniSub.unsubscribe();
        }
    }

}
