import {Component, OnDestroy, OnInit} from '@angular/core';
import {headerAnimations} from './header.animations';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    animations: headerAnimations,
})
export class HeaderComponent implements OnInit, OnDestroy {
    mobileMenuAnimationStatus = 'closed';
    lottieConfig: Object;
    lastDirection = -1;
    private anim: any;

    constructor(
        private router: Router
    ) {
        this.lottieConfig = {
            path: '/assets/images/menu.json',
            renderer: 'svg',
            autoplay: false,
            loop: false
        };
    }

    active: string;
    backgroundColor = '#093650';
    subscription: Subscription;

    ngOnInit() {
        this.active = this.router.url;
        this.defineBackgroundColor(this.active);
        this.subscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd) {
                this.active = val.urlAfterRedirects;
                this.defineBackgroundColor(this.active);

                if(this.mobileMenuAnimationStatus == 'open') {
                    this.openMenu();
                }
            }
        });
    }

    defineBackgroundColor(bgColor: string) {
        switch (bgColor) {
            case '/':
            case '/destinazioni':
            case '/rischio-malaria-e-prevenzione':
                this.backgroundColor = '#093650';
                break;
            case '/centri-vaccinazione':
            case '/dieci-regole-del-viaggiatore':
                this.backgroundColor = '#D35C1E';
                break;
            case '/viaggiatori-particolari':
                this.backgroundColor = '#E5A813';
                break;
            case '/covid-19':
                this.backgroundColor = '#056095';
                break;
        }

        if(bgColor.startsWith('/centri-vaccinazione')) {
            this.backgroundColor = '#D35C1E';
        }

        if(bgColor.startsWith('/destinazioni/')) {
            this.backgroundColor = '#085F91';
        }
    }

    handleAnimation(anim: any) {
        this.anim = anim;
        this.anim.setSpeed(1.5);
    }

    stop() {
        this.anim.stop();
    }

    play() {
        this.anim.play();
    }

    pause() {
        this.anim.pause();
    }

    openMenu() {
        this.lastDirection = this.lastDirection > 0 ? -1 : 1;
        this.anim.setDirection(this.lastDirection);
        this.anim.play();
        this.mobileMenuAnimationStatus = this.mobileMenuAnimationStatus == 'closed' ? 'open' : 'closed';

        // Blocking/Unblocking body
        let status = 'auto';
        if(this.mobileMenuAnimationStatus == 'open') {
            status = 'hidden';
        }

        // (document.querySelector('body') as HTMLElement).style.overflow = status;
        // (document.querySelector('#app-container') as HTMLElement).style.overflow = status;
    }

    ngOnDestroy() {
        if(!this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}
