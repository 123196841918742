import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {

    constructor(
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = environment.STS_TOKEN;

        const cp = req.clone({
            headers: req.headers.set('token', token),
        });

        return next.handle(cp);
    }
}
