import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class CentriVaccinazioneApiService {

    constructor(
        protected httpClient: HttpClient
    ) { }

    getCentriVaccinazione() {
        return this.httpClient.get(environment.API_URL + '/centri_vaccinazione');
    }

    getCentri(localita: string) {
        return this.httpClient.get(environment.API_URL + '/centri_vaccinazione/find?localita=' + btoa(localita));
    }
}
