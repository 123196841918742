<section id="coronavirus">
    <div id="coronavirus-head">
        <div id="coronavirus-head-container">

            <img src="/assets/images/header_dot_shadow.svg" />
            <div id="coronavirus-img">
                <img id="" src="/assets/images/corona_virus_white.svg" />
            </div>

            <div id="coronavirus-title">
                COVID-19 (Coronavirus)
            </div>

            <div id="corona-menu">
                <ul>
                    <li>
                        SINTOMI
                    </li>
                    <li>
                        COME SI TRASMETTE IL CORONAVIRUS?
                    </li>
                    <li>
                        MISURE IGIENICO-SANITARIE
                    </li>
                    <li>
                        VAGGIATORI PROVENIENTI DALLE ZONE ROSSE (LOMBARDIA, EMILIA, VENETO, PIEMONTE)
                    </li>
                    <li>
                        DONNE GRAVIDE
                    </li>
                    <li>
                        NUMERI UTILI
                    </li>
                </ul>
            </div>

            <div id="coronavirus-box">
                <div class="corona-title" id="sintomi">SINTOMI</div>

                <div class="corona-subtitle">Quali sono i sintomi che presenta una persona infetta da coronavirus?</div>
                <div class="corona-content">
                    I sintomi più comuni includono febbre, tosse, difficoltà respiratorie.
                    Nei casi più gravi, l'infezione può causare polmonite, sindrome respiratoria acuta grave,
                    insufficienza renale. Come altre malattie respiratorie, l’infezione da nuovo coronavirus può causare
                    sintomi lievi come raffreddore, mal di gola, tosse e febbre, oppure sintomi più severi quali polmonite e
                    difficoltà respiratorie. Raramente può essere fatale. Generalmente i sintomi sono lievi e a inizio lento.
                    Alcune persone si infettano ma non sviluppano sintomi né malessere. La maggior parte delle persone (circa l'80%)
                    guarisce dalla malattia senza bisogno di cure speciali. Circa 1 persona su 6 con COVID-19 si ammala gravemente e
                    sviluppa difficoltà respiratorie. Le persone più suscettibili alle forme gravi sono gli anziani e quelle con
                    malattie pre-esistenti, quali diabete e malattie cardiache. Il periodo di incubazione rappresenta il periodo di tempo
                    che intercorre fra il contagio e lo sviluppo dei sintomi clinici. Si stima attualmente che vari fra 2 e 11 giorni,
                    fino ad un massimo di 14 giorni.
                </div>

                <div class="corona-subtitle">Ho sintomi influenzali/respiro male, cosa devo fare?</div>
                <div class="corona-content">
                    Nel caso in cui la persona che telefona
                    lamenti stati di malessere compatibili con la sindrome
                    da COVID-19 ovvero febbre, tosse, problemi respiratori, congiuntivite,
                    bisogna orientarla subito verso il proprio medico curante sconsigliando di recarsi al Pronto Soccorso dove,
                    nell’ipotesi in cui fosse affetto da Coronavirus, potrebbe correre il rischio di propagare il virus. In questi
                    casi potrebbe risultare utile passare la telefonata al personale medico, se presente in Sala Operativa. Talvolta
                    si chiede all’operatore quali farmaci prendere. È opportuno che anche i medici in Sala Operativa si astengano dal
                    fornire consigli o indicazioni e si limitino a invitare il soggetto che lo richiede a rivolgersi al medico curante.
                     Il collegamento epidemiologico può essere avvenuto entro un periodo di 14 giorni prima o dopo la manifestazione della
                    malattia nel caso in esame.
                </div>

                <div class="corona-title" id="come-si-trasmette">COME SI TRASMETTE IL CORONAVIRUS?</div>
                <div class="corona-content">
                    Il Coronavirus è un virus respiratorio che si diffonde principalmente attraverso il contatto stretto con una persona malata. La via primaria sono le
                    goccioline del respiro delle persone infette ad esempio tramite: <strong>la saliva</strong>, <strong>tossendo e starnutendo </strong>,
                    <strong>contatti diretti personali </strong>, <strong>le mani</strong>, ad esempio toccando con le mani contaminate (non ancora lavate) bocca,
                    naso o occhi In casi rari il contagio può avvenire attraverso contaminazione fecale.  Normalmente le malattie respiratorie non
                    si trasmettono con gli alimenti che, comunque, devono essere manipolati rispettando le buone pratiche igieniche ed evitando
                    il contatto fra alimenti crudi e cotti. Secondo i dati attualmente disponibili, le persone sintomatiche sono la causa più
                    frequente di diffusione del virus. L’OMS considera non frequente l’infezione da nuovo Coronavirus prima che sviluppino sintomi.
                </div>

                <div class="corona-title" id="misure-igienico-sanitarie">MISURE IGIENICO-SANITARIE</div>

                <div class="corona-content">
                    <ul>
                        <li>Lavarsi spesso le mani. Si raccomanda di mettere a disposizione in tutti i locali pubblici, palestre, supermercati, farmacie e altri luoghi di aggregazione, soluzioni idro-alcoliche per il lavaggio delle mani;</li>
                        <li>evitare il contatto ravvicinato con persone che soffrono di infezioni respiratorie acute;</li>
                        <li>evitare abbracci e strette di mano;</li>
                        <li>mantenimento, nei contatti sociali, di una distanza interpersonale di almeno un metro;</li>
                        <li>curare l’igiene respiratoria (starnutire e/o tossire in un fazzoletto evitando il contatto delle mani con le secrezioni respiratorie);</li>
                        <li>evitare l’uso promiscuo di bottiglie e bicchieri, in particolare durante l’attività sportiva;</li>
                        <li>non toccarsi occhi, naso e bocca con le mani;</li>
                        <li>coprirsi bocca e naso se si starnutisce o tossisce;</li>
                        <li>non prendere farmaci antivirali e antibiotici, a meno che siano prescritti dal medico;</li>
                        <li>pulire le superfici con disinfettanti a base di cloro o alcol;</li>
                        <li>usare la mascherina solo se si sospetta di essere malati o se si presta assistenza a persone malate.</li>
                    </ul>
                </div>

                <div class="corona-title" id="viaggiatori-zone-rosse">VAGGIATORI PROVENIENTI DALLE ZONE ROSSE (LOMBARDIA, EMILIA, VENETO, PIEMONTE)</div>

                <div class="corona-subtitle">Cosa devo fare?</div>
                <div class="corona-content">
                    Chiunque, a partire dal 22 febbraio abbia fatto ingresso in Italia dopo aver soggiornato in zone a rischio epidemiologico,
                    come identificate dall’OMS, o sia transitato o abbia sostato nelle cosiddette “Zone rosse”, deve comunicare tale circostanza al
                    Comune, al Dipartimento di prevenzione dell’azienda sanitaria competente per territorio nonché al proprio medico di medicina generale
                    ovvero al pediatra di libera scelta. <br />
                    In base al decreto, quindi, è tenuto a comunicare il proprio ingresso in Sicilia all’azienda sanitaria (o attraverso medico di medicina
                    generale e/o pediatra) ESCLUSIVAMENTE chi sia rientrato da una delle zone a rischio (cd. Zona rossa), ovvero:

                    <ul [style.marginTop]="'15px'">
                        <li>dall’estero: Cina, Corea del Sud, Giappone, Iran</li>
                        <li>dall’Italia: della Regione Lombardia e delle Province di Modena, Parma,</li>
                        <li>Piacenza, Reggio nell’Emilia, Rimini; Pesaro e Urbino; Venezia, Padova, Treviso; Asti e Alessandria,  Novara, Verbanio-Cusio-Ossola, Vercelli</li>
                    </ul>

                    Se il soggetto NON PROVIENE da una delle zone indicate al punto 1. NON è NECESSARIO comunicare il proprio stato all’azienda sanitaria.
                    Se ritiene di avere sintomi conducibili ad una ipotesi di coronavirus potrà chiamare il proprio medico curante e chiedere indicazioni.
                </div>

                <div class="corona-subtitle">Non ho sintomi ma voglio sottopormi ad una fase di quarantena volontaria, cosa devo fare?</div>
                <div class="corona-content">
                    Nel caso in cui un soggetto proveniente da una delle regioni maggiormente a rischio volesse, comunque,
                    tenere una condotta di isolamento fiduciario (quarantena volontaria) dovrà avvertire il dipartimento di
                    prevenzione dell’azienda sanitaria competente per territorio nonché
                    il proprio medico curante e seguire le regole previste per la permanenza domiciliare.
                </div>

                <div class="corona-subtitle">Ho sintomi influenzali/respiro male, cosa devo fare?</div>
                <div class="corona-content">
                    Nel caso in cui la persona che telefona lamenti stati di malessere compatibili con la sindrome da COVID-19 ovvero febbre,
                    tosse, problemi respiratori, congiuntivite, bisogna orientarla subito verso il proprio medico curante sconsigliando
                    di recarsi al Pronto Soccorso dove, nell’ipotesi in cui fosse affetto da Coronavirus, potrebbe correre il rischio di
                    propagare il virus. In questi casi potrebbe risultare utile passare la telefonata al personale medico, se presente in
                    Sala Operativa. Talvolta si chiede all’operatore quali farmaci prendere. È opportuno che anche i medici in Sala Operativa
                    si astengano dal fornire consigli o indicazioni e si limitino a invitare il soggetto che lo richiede a rivolgersi al medico
                    curante. Il collegamento epidemiologico può essere avvenuto entro un periodo di 14 giorni prima o dopo la manifestazione
                    della malattia nel caso in esame.
                </div>

                <div class="corona-subtitle">Posso sottopormi privatamente ad analisi del sangue, o di altri campioni biologici, per sapere se ho contratto il coronavirus?</div>
                <div class="corona-content">
                    No. Non esistono al momento kit commerciali per confermare la diagnosi di infezione da nuovo coronavirus. La diagnosi
                    deve essere eseguita nei laboratori di riferimento regionale, su campioni clinici respiratori secondo i protocolli indicati
                    dall’OMS. In caso di positività al nuovo coronavirus, la diagnosi deve essere confermata dal laboratorio di riferimento nazionale
                    dell’Istituto Superiore di Sanità. Secondo le indicazioni del Consiglio Superiore della Sanità, sulla base delle evidenze scientifiche
                    finora disponibili, non è raccomandata l’esecuzione del tampone ai casi asintomatici.
                </div>

                <div class="corona-subtitle">Ho più di 65 anni, che devo fare?</div>
                <div class="corona-content">
                    I provvedimenti adottati dal Governo, fanno espressa raccomandazione alle persone anziane, affette da patologie croniche
                    o con multimorbilità ovvero con stati di immunodepressione congenita o acquisita, di evitare di uscire dal proprio alloggio
                    fuori dai casi di stretta necessità e di evitare, comunque, luoghi affollati nei quali non sia possibile mantenere la distanza
                    di sicurezza interpersonale di almeno un metro.
                </div>

                <div class="corona-subtitle">Si può contrarre il nuovo coronavirus attraverso il contatto con le maniglie di autobus o sulla metropolitana/bus/treno stando vicini a una persona che tossisce?</div>
                <div class="corona-content">
                    Il nuovo coronavirus è un virus respiratorio che si diffonde principalmente attraverso il contatto con le goccioline
                    del respiro delle persone infette, ad esempio quando starnutiscono o tossiscono o si soffiano il naso.
                    È buona norma, per prevenire infezioni, anche respiratorie, il lavaggio frequente e accurato delle mani, dopo aver
                    toccato oggetti e superfici potenzialmente sporchi, prima di portarle al viso, agli occhi e alla bocca. Per chi
                    opera negli uffici è utile disinfettare con soluzioni alcoliche la tastiera del pc e i piani su cui opera.
                    Proprio perché si propaga con le goccioline di saliva è importante che le persone ammalate applichino misure di
                    igiene quali starnutire o tossire in un fazzoletto o all’interno del gomito e gettare i fazzoletti utilizzati in un
                    cestino chiuso immediatamente dopo l'uso. È da tener presente, comunque, che siamo nel pieno della stagione influenzale.
                    Pertanto, se dovessero comparire sintomi come febbre, tosse, mal di gola, mal di testa e, in particolare, difficoltà
                    respiratorie, ancorché non siamo dinnanzi a coronavirus sarà prudente rivolgersi al proprio medico curante.
                </div>

                <div class="corona-subtitle">Devo indossare la mascherina per proteggermi?</div>
                <div class="corona-content">
                    L’Organizzazione Mondiale della Sanità raccomanda di indossare una mascherina
                    solo se si sospetti di aver contratto il nuovo Coronavirus e siano presenti sintomi quali tosse o
                    starnuti ovvero nel caso ci si prenda cura di una persona con sospetta infezione da nuovo Coronavirus.
                    L’uso della mascherina aiuta a limitare la diffusione del virus ma deve essere adottata in aggiunta ad altre misure di
                    igiene respiratoria e delle mani. Non è utile indossare più mascherine sovrapposte. L'uso razionale delle mascherine è
                    importante per evitare inutili sprechi di risorse preziose.
                </div>

                <div class="corona-subtitle">Cosa devo fare se sospetto di aver contratto il coronavirus?</div>
                <div class="corona-content">
                    In tutti i casi in cui si abbia il sospetto di aver contratto il Coronavirus o in cui i sintomi siano compatibili
                    con il Coronavirus occorre chiamare il proprio medico curante il quale valuterà le condizioni del proprio assistito
                    ed eventualmente segnalerà il caso alle Autorità sanitarie territorialmente competenti per le eventuali prescrizioni.
                </div>


                <div class="corona-title" id="donne-gravide">DONNE GRAVIDE</div>

                <div class="corona-subtitle">Le donne in gravidanza sono più suscettibili alle infezioni o hanno un rischio maggiore di sviluppare una forma severa di COVID-19? </div>
                <div class="corona-content">
                    Non sono riportati dati scientifici sulla suscettibilità delle donne in gravidanza al virus. La gravidanza
                    comporta cambiamenti del sistema immunitario che possono aumentare il rischio di contrarre infezioni respiratorie
                    virali, tra cui quella da SARS-CoV-2. Inoltre le donne in gravidanza potrebbero mostrare un rischio maggiore di sviluppare
                    una forma severa di infezioni respiratorie virali. Resta consigliato, anche per le donne in gravidanza, di intraprendere
                    le normali azioni preventive per ridurre il rischio di infezione, come lavarsi spesso le mani ed evitare contatti con
                    persone malate.
                </div>

                <div class="corona-subtitle">Quali sono gli effetti di COVID-19 durante la gravidanza? </div>
                <div class="corona-content">
                    Non sono riportati dati scientifici sugli effetti di COVID-19 durante la gravidanza. In caso di infezione
                    in corso di gravidanza da altri coronavirus correlati [SARS-CoV e MERS-CoV] sono stati osservati casi di
                    aborto spontaneo mentre la presenza di febbre elevata durante il primo trimestre di gravidanza può aumentare
                    il rischio di difetti congeniti.
                </div>

                <div class="corona-subtitle">Le donne in gravidanza con COVID-19 possono trasmettere il virus al feto o neonato? </div>
                <div class="corona-content">
                    Dai dati presenti in letteratura, limitati, non sono stati riportati casi di trasmissione dell’infezione da
                    altri coronavirus (MERS-CoV e SARS-CoV) da madre a figlio. I dati recenti riguardo bambini nati da madri con
                    COVID-19 indicano che nessuno di essi è risultato positivo. Inoltre, il SARS- CoV- 2 non è stato rilevato nel
                    liquido amniotico.
                </div>

                <div class="corona-title" id="numeri utili">NUMERI UTILI</div>

                <div class="corona-subtitle">Numeri verdi regionali</div>
                <div class="corona-content">
                    Le Regioni hanno attivato numeri dedicati per rispondere alle richieste di informazioni e sulle misure urgenti per
                    il contenimento e la gestione del contagio del nuovo coronavirus in Italia:

                    <ul [style.marginTop]="'15px'">
                        <li>Basilicata: 800 99 66 88 </li>
                        <li>Calabria: 800 76 76 76</li>
                        <li>Campania: 800 90 96 99</li>
                        <li>Emilia-Romagna: 800 033 033</li>
                        <li>Friuli Venezia Giulia: 800 500 300</li>
                        <li>Lazio: 800 11 88 00</li>
                        <li>Lombardia: 800 89 45 45</li>
                        <li>Marche: 800 93 66 77</li>
                        <li>
                            Piemonte:
                            <ul>
                                <li>800 19 20 20 - attivo 24 ore su 24</li>
                                <li>800 333 444 - attivo dal lunedì al venerdì, dalle ore 8 alle 20</li>
                            </ul>
                        </li>
                        <li>Provincia autonoma di Trento: 800 867 388</li>
                        <li>Provincia autonoma di Bolzano: 800 751 751</li>
                        <li>Puglia: 800 713 931</li>
                        <li>Sardegna: 800 311 377</li>
                        <li>Sicilia: 800 45 87 87</li>
                        <li>Toscana: 800 55 60 60</li>
                        <li>Umbria: 800 63 63 63</li>
                        <li>Val d’Aosta: 800 122 121</li>
                        <li>Veneto: 800 462 340</li>
                    </ul>
                </div>

                <div class="corona-subtitle">Numero di pubblica utilità</div>
                <div class="corona-content">
                    E’ attivo il numero di pubblica utilità 1500 del Ministero della salute.
                </div>

                <div class="corona-subtitle">Numero unico di emergenza</div>
                <div class="corona-content">
                    Contattare il 112 oppure il 118 soltanto se strettamente necessario.  
                </div>
            </div>
        </div>
    </div>
</section>