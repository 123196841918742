import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Destinazione} from "../../../../../Models/destinazioni.model";
import {DestinazioniApiService} from "../../../../../Services/API/destinazioni-api.service";
import {MetatagsService} from "../../../../../Services/Shared/metatags.service";

@Component({
    selector: 'app-destinazione',
    templateUrl: './destinazione.component.html',
    styleUrls: ['./destinazione.component.css']
})
export class DestinazioneComponent implements OnInit, OnDestroy {

    destinazione: Destinazione;
    destinazioneId: number;
    private sub: any;
    selectedMenu = "fuso";
    selectedcontent = "vaccinazioni";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private destinazioniApi: DestinazioniApiService,
        private metatagsService: MetatagsService
    ) { }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.destinazioneId = parseInt(params['destinazione']);
            if(!isNaN(this.destinazioneId) && this.destinazioneId > 0) {
                // Find destinazione
                this.destinazioniApi.getDestinazione(this.destinazioneId).subscribe((data: Destinazione)=> {
                    if(data.id === undefined) {
                        this.router.navigate(['404']);
                    } else {
                        this.destinazione = data;

                        this.metatagsService.updateTitle("iViaggio - " + this.destinazione.nazione);
                        this.metatagsService.updateDescription("Viaggia informato. Scopri tutto su: " + this.destinazione.nazione);
                        this.metatagsService.updateUrl();
                    }
                });
            } else {
                this.router.navigate(['404']);
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
