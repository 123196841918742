<section id="destinazioni">
    <div id="destinazioni-head">
        <div id="destinazioni-head-container">
            <div id="destinazioni-head-text">Dove vuoi andare?</div>
            <div id="destinazioni-head-input">
                <img src="/assets/images/icon_dove_vuoi_andare.svg" />
                <input type="text" class="form-control" (input)="onSearch($event.target.value)" placeholder="Cerca qui...">
            </div>
        </div>
    </div>

    <div id="countriesList">
        <img id="loader" src="/assets/images/loader.svg" *ngIf="!hasLoaded" />
        <img id="no-results" src="/assets/images/no_result_img.svg" *ngIf="hasLoaded && destinazioni.length <= 0" />
        <div class="country-box" *ngFor="let destinazione of destinazioni">
            <div class="country-head" [style.display]="destinazione.nazioni.length == 0 ? 'none' : 'block'">{{destinazione.continente}}</div>
            <div class="country-element" *ngFor="let nazione of destinazione.nazioni"  [routerLink]="['/','destinazioni', nazione.id]">{{nazione.nome}}</div>
        </div>
    </div>
</section>