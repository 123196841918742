<section id="dieci-regole">
    <div id="dieci-regole-head">
        <div id="dieci-regole-head-container">

            <img src="/assets/images/header_dot_shadow.svg" />
            <div id="dieci-regole-img">
                <img id="" src="/assets/images/icon_10regole_white.svg" />
            </div>

            <div id="dieci-regole-title">
                Le 10 regole del viaggiatore
            </div>

            <div id="dieci-regole-box">
                <div class="dr-number">1 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    3-4 settimane prima di partire: verifica se occorrono vaccinazioni obbligatorie o raccomandate,
                    rivolgendoti all’Ambulatorio per i Viaggiatori internazionali della tua ASL.
                </div>

                <div class="dr-number">2 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Assicurazione sanitaria: verifica se sei assicurato e cosa occorre fare presso l’ASL o l’Agenzia di Viaggi.
                </div>

                <div class="dr-number">3 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Attenzione a ciò che mangi:
                    <br />
                    <br />
                    <strong>NO a:</strong> cibi freddi o riscaldati, i buffet freddi di carne, pesce o molluschi, le creme all’uovo o alla panna, salse crude all’uovo.
                    <br />
                    <br />
                    <strong>SI’ a:</strong> carne e pesce ben cotti e serviti ancora caldi, frutta e verdura da sbucciare o pelare, verdura cotta.
                </div>

                <div class="dr-number">4 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Attenzione a ciò che bevi: spesso l’acqua del rubinetto e delle fontane non è potabile.
                    Usa acqua minerale in bottiglie sigillate a tavola e per lavare i denti; evita il ghiaccio.
                </div>

                <div class="dr-number">5 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Evita rapporti sessuali occasionali: nel caso usa sempre il preservativo.
                </div>

                <div class="dr-number">6 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Farmaci da viaggio. Ricordati di portare:
                    <br />
                    <br />
                    <ul [style.textAlign]="'left'">
                        <li>farmaci che assumi abitualmente, in scorte sufficienti, compresi liquidi per lenti a contatto, pillola anticoncezionale;</li>
                        <li>antimalarici (nei casi indicati);</li>
                        <li>farmaci che potrebbero essere utili (antidolorifici, antibiotici);</li>
                        <li>attrezzatura varia (creme solari a fattore protettivo molto alto, forbici, cerotti, salviettine disinfettanti).</li>
                    </ul>
                </div>

                <div class="dr-number">7 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Per prevenire la malaria: Proteggiti dalle punture di zanzare e, quando necessario, assumi farmaci antimalarici. La chemioprofilassi va iniziata prima dell’arrivo in zona malarica, assunta con regolarità durante tutto il periodo di permanenza e continuata ancora dopo aver lasciato la zona a rischio. Molti disattendono proprio a questo consiglio, permettendo lo sviluppo della malattia che, durante il soggiorno era ancora in incubazione, Anche una sola interruzione dell’assunzione del farmaco, infatti, diminuisce l’effetto protettivo (naturalmente la dose va ripresa il più presto possibile).
                </div>

                <div class="dr-number">8 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Al rientro: nei mesi successivi al rientro se compare febbre di origine non chiara, ricordati di dire al tuo medico che sei stato in una zona malarica.
                </div>

                <div class="dr-number">9 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Traffico stradale: fai molta attenzione alla guida tua e degli altri. In molti paesi in via di sviluppo gli incidenti stradali sono molto frequenti e spesso gravi.
                </div>

                <div class="dr-number">10 <sup>a</sup> REGOLA</div>
                <div class="dr-text">
                    Criminalità: stai attento a non esporre troppo denaro e oggetti di valore, specie nei quartieri e nelle aree pericolose che la guida ti indicherà.
                </div>
            </div>
        </div>
    </div>
</section>